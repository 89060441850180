var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-page-header',{attrs:{"title":_vm.title,"sub-title":"源数据管理，操作即时生效，谨慎操作！"},on:{"back":function () { return _vm.$router.go(-1); }}}),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"main-content-header"},[_c('div',{staticClass:"item",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title-1"},[_vm._v(_vm._s(_vm.title)+"（源数据）")])]),_c('div',{staticClass:"item area-cascader"},[_c('span',{staticClass:"ribbon-label"},[_vm._v("按地区查询：")]),_c('a-cascader',{attrs:{"options":_vm.cascaderAreaOptions,"placeholder":"选择地区","expand-trigger":"hover","field-names":_vm.fieldNames},model:{value:(_vm.areaId),callback:function ($$v) {_vm.areaId=$$v},expression:"areaId"}})],1)]),_c('div',{staticClass:"item"},[_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"primary"},on:{"click":_vm.onSearchs}},[_vm._v(" 查 询 ")]),_c('a-button',{staticStyle:{"margin-right":"10px"},on:{"click":_vm.onReset}},[_vm._v(" 重 置 ")]),_c('a-button',{staticStyle:{"margin-right":"10px"},on:{"click":_vm.Import}},[_vm._v(" 导入 ")])],1)]),_c('my-table',{attrs:{"columns":_vm.columns,"data-source":_vm.dataSource,"pagination":false,"loading":_vm.loading,"scroll":{ x: 1220 }},on:{"expand":_vm.handleExpand},scopedSlots:_vm._u([{key:"cover",fn:function(record){return [_c('div',{staticClass:"cover"},[_c('img',{staticClass:"img",attrs:{"src":record.cover,"alt":"cover"}})])]}},{key:"action",fn:function(record){return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleImport(record)}}},[_vm._v(" 导入"+_vm._s(record.name)+"服务 ")]),_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.$jump({
              path: '/publicAreaIdAdd',
              query: {
                categoryId: _vm.categoryId,
                servingId: record.id,
                areaId:_vm.areaId
              }
            })}}},[_vm._v(" 编辑 ")])]}},{key:"expandedRowRender",fn:function(innerRecord){return _c('a-table',{staticClass:"inner-table",attrs:{"columns":_vm.innerColumns,"data-source":innerRecord.data,"pagination":false,"row-key":function (record) { return record.id; },"loading":_vm.expandedLoading,"scroll":{ x: 1320 }},scopedSlots:_vm._u([{key:"cover",fn:function(records){return [_c('div',{staticClass:"cover"},[_c('img',{staticClass:"img",attrs:{"src":records.cover,"alt":"cover"}})])]}},{key:"price",fn:function(records){return [_c('a-statistic',{attrs:{"prefix":"￥","value":records.price}})]}},{key:"innerAction",fn:function(record){return [_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleEdit(record)}}},[_vm._v(" 编辑 ")]),_c('a-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleDelete(record)}}},[_vm._v(" 删除 ")])]}}])})}}])})],1),_c('drawer',{attrs:{"title":_vm.drawerTitle,"width":376,"visible":_vm.visible}},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"rules":_vm.rules}},[_c('a-form-model-item',{ref:"name",staticClass:"ant-form-item-input",attrs:{"label":"设备名称","prop":"name"}},[_c('a-input',{attrs:{"placeholder":"请输入名称"},on:{"blur":function () {
              _vm.$refs.name.onFieldBlur()
            }},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',{ref:"price",attrs:{"label":"服务费用","prop":"price"}},[_c('a-input',{attrs:{"placeholder":"请输入服务费用"},on:{"blur":function () {
              _vm.$refs.price.onFieldBlur()
            }},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1),_c('a-form-model-item',{ref:"subtitle",attrs:{"label":"设备属性","prop":"subtitle"}},[_c('a-input',{attrs:{"placeholder":"请输入设备属性"},on:{"blur":function () {
              _vm.$refs.subtitle.onFieldBlur()
            }},model:{value:(_vm.form.subtitle),callback:function ($$v) {_vm.$set(_vm.form, "subtitle", $$v)},expression:"form.subtitle"}})],1),_c('a-form-model-item',{ref:"specifiId",attrs:{"label":"设备规格","prop":"specifiId"}},[_c('a-select',{attrs:{"placeholder":"请选择规格"},model:{value:(_vm.form.specifiId),callback:function ($$v) {_vm.$set(_vm.form, "specifiId", $$v)},expression:"form.specifiId"}},_vm._l((_vm.specifis),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-model-item',{ref:"sort",attrs:{"label":"排序","prop":"sort"}},[_c('a-input',{attrs:{"placeholder":"请输入排序"},on:{"blur":function () {
              _vm.$refs.sort.onFieldBlur()
            }},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}})],1),_c('a-form-model-item',{attrs:{"prop":"cover","label":"展示图","extra":"建议使用PNG格式图片；尺寸为200px*200px"}},[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"nname":"file","list-type":"picture-card","show-upload-list":false,"action":_vm.uploadUrl,"before-upload":_vm.beforeUpload,"data":{
            token: _vm.qiniuData.token
          }},on:{"change":_vm.handleChange}},[(_vm.form.cover)?_c('div',{staticClass:"avatar-box"},[_c('img',{staticClass:"img",attrs:{"src":_vm.form.cover,"alt":"avatar"}})]):_c('div',[_c('a-icon',{attrs:{"type":_vm.imgLoading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("上传图片")])],1)])],1)],1),_c('div',{staticClass:"drawer-btn-wrap"},[_c('a-button',{on:{"click":_vm.handleDrawerClose}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleDrawerSubmit}},[_vm._v(" 提交 ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }