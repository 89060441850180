<template>
  <!-- 常驻业务线配置详情 -->
  <div class="container">
    <a-page-header
      :title="title"
      :sub-title="subTitle"
      @back="() => $router.go(-1)"
    />

    <div class="main-content">
      <!-- <a-tabs
        :default-active-key="tabIndex"
        :animated="false"
        @change="callback"
      >
        <a-tab-pane v-for="item in tabs" :key="item.key" :tab="item.name"> -->
      <div class="main-content-header">
        <div class="item">
          <div class="title">
            <span class="title-1">常驻业绩</span>
          </div>
        </div>
        <div class="item">
          <a-button type="primary" @click="handleModalOpen">
            新增角色
          </a-button>
        </div>
      </div>
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :row-key="(record) => record.id"
          :loading="loading"
        >
          <template slot="rate" slot-scope="records">
            <!-- <template v-if="record.records === 1"> -->
            <a-input v-if="records.editable" v-model="newRate" />
            <template v-else>
              <span>{{ parseFloat(records.rate*100).toFixed(0) }}%</span>
            </template>
            <!-- </template> -->
            <!-- {{ records.rate * 100 }} -->
          </template>
          <template slot="action" slot-scope="text, record, index">
            <div class="editable-row-operations">
              <span v-if="record.editable">
                <a-button type="link" @click="() => save(index)">保存</a-button>
                <a-popconfirm
                  title="确定取消?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="() => cancel(index)"
                >
                  <a-button type="link">取消</a-button>
                </a-popconfirm>
              </span>
              <template v-else>
                <a-button
                  type="link"
                  :disabled="editingKey !== ''"
                  @click="() => edit(index)"
                >
                  编辑
                </a-button>
                <a-button type="link" @click="onDel(record)"> 删除 </a-button>
              </template>
            </div>
          </template>
        </a-table>
      </div>
      <!-- </a-tab-pane>
      </a-tabs> -->
    </div>
    <!-- '-' + tabs[tabIndex].name + -->
    <a-modal
      :title="title + '-新建角色'"
      :visible="modalVisible"
      :confirm-loading="confirmModalLoading"
      @ok="handleModalOk"
      @cancel="handleModalCancel"
    >
      <a-form-model ref="ruleModalForm" :model="modalForm" :rules="modalRules">
        <a-form-model-item label="选择角色" prop="rose_id">
          <a-cascader
            v-model="modalForm.rose_id"
            :options="roleData"
            expand-trigger="hover"
            placeholder="请选择角色"
            :field-names="fieldNames"
            @change="onRoseChange"
          />
        </a-form-model-item>

        <a-form-model-item ref="rate" label="分成比例" prop="rate">
          <a-input
            v-model="modalForm.rate"
            placeholder="请输入分成比例"
            @blur="
              () => {
                $refs.rate.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <!-- <a-modal
      :title="title +'-增加二级分成角色'"
      :visible="modalSubVisible"
      :confirm-loading="confirmModalSubLoading"
      @ok="handleModalSubOk"
      @cancel="handleModalSubCancel"
    >
      <a-form-model
        ref="ruleModalSubForm"
        :model="modalSubForm"
        :rules="modalSubRules"
      >
        <a-form-model-item label="选择角色" prop="rose_id">
          <a-cascader
            v-model="modalSubForm.rose_id"
            :options="roleData"
            expand-trigger="hover"
            placeholder="请选择角色"
            :field-names="fieldNames"
          />
        </a-form-model-item>
        <a-form-model-item label="分成基数设置">
          <a-cascader placeholder="一级角色实际所得金额" disabled />
        </a-form-model-item>
        <a-form-model-item
          ref="rate"
          label="分成比例"
          prop="rate"
          v-if="tabIndex < 2"
        >
          <a-input
            v-model="modalSubForm.rate"
            placeholder="请输入分成比例"
            @blur="
              () => {
                $refs.rate.onFieldBlur()
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal> -->

    <!-- 星级配置修改-start -->
    <a-modal
      :title="modalStarTitle + '-星级阶梯式'"
      :visible="modalStarVisible"
      @cancel="handleModalStarCancel"
    >
      <div class="star">
        <div class="title">
          <div class="item left">角色星级</div>
          <div class="item right">业绩比例（%）</div>
        </div>
        <div class="body">
          <div class="item" v-for="item in stars" :key="item.id">
            <div class="left">
              <a-rate v-model="item.level" disabled />
              <span class="label">{{ item.name }}</span>
            </div>
            <div class="right">
              <a-input v-if="starEditable" v-model="item.rate" />
              <template v-else> {{ item.rate }}% </template>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer" v-if="starEditable">
        <a-button key="back" @click="handleModalStarCancel">
          取消修改
        </a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="confirmModalStarLoading"
          @click="handleModalStarOk"
        >
          提交变更
        </a-button>
      </template>
      <template slot="footer" v-else>
        <a-button key="back" @click="starEditable = true">
          编辑业绩比例
        </a-button>
      </template>
    </a-modal>
    <!-- 星级配置修改-end -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NP from 'number-precision'
let bcid = ''
let parentId = ''
let starId = ''
let validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入分成比例'))
  } else {
    if (value > 100) {
      callback(new Error('分成比例不能超过100%'))
    } else {
      callback()
    }
  }
}
export default {
  data() {
    return {
      title: '',
      subTitle: '业务主线',
      tabIndex: 0,
      list: [],
      //   tabs: [
      //     {
      //       key: 0,
      //       name: '常驻业绩',
      //       data: []
      //     },
      //     {
      //       key: 1,
      //       name: '市场费',
      //       data: []
      //     },
      //     {
      //       key: 2,
      //       name: '渠道费',
      //       data: []
      //     }
      //   ],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '角色名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '业绩比例',
          scopedSlots: { customRender: 'rate' }
        },
        {
          title: '操作',
          width: 300,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true,
      editingKey: '', // 修改配置所在列表索引
      newRate: '', // 修改配置值
      innerColumns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '二级角色名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '分成基数设置',
          scopedSlots: { customRender: 'base' },
          width: 200
        },
        {
          title: '二级分成比例',
          scopedSlots: { customRender: 'rate' }
        },
        {
          title: '操作',
          width: 220,
          scopedSlots: { customRender: 'action' }
        }
      ],
      confirmModalLoading: false,
      modalVisible: false,
      modalForm: {
        rose_id: undefined,
        rate: ''
      },
      rose_idConfig: '',
      modalRules: {
        rose_id: [{ required: true, message: '请选择角色', trigger: 'change' }],
        rate: [{ validator: validatePass, trigger: 'blur' }]
      },
      fieldNames: {
        label: 'name',
        value: 'id',
        children: 'role'
      },
      roleData: [],
      confirmModalSubLoading: false,
      modalSubVisible: false,
      modalSubForm: {
        rose_id: undefined,
        rate: ''
      },
      modalSubRules: {
        rose_id: [{ required: true, message: '请选择角色', trigger: 'change' }],
        rate: [{ validator: validatePass, trigger: 'blur' }]
      },
      performanceTypes: [
        {
          id: 1,
          name: '一级'
        },
        {
          id: 2,
          name: '星级阶梯式'
        }
      ],
      performanceType: 1, // 业绩类型
      roleType: 1, // 角色业绩类型，默认类型：一级
      stars: [], // 对应角色的星级业绩比例等
      modalStarTitle: '',
      confirmModalStarLoading: false,
      modalStarVisible: false, // 是否显示对应角色的星级业绩比例等
      starEditable: false // 编辑对应角色的星级业绩比例
    }
  },
  computed: {
    ...mapState(['pageTitle'])
  },
  created() {
    const query = this.$route.query
    bcid = query.id
    console.log('bcid', bcid)
    this.title = query.name
    this.getBcRoselist()
  },
  methods: {
    // callback(key) {
    //   this.tabIndex = key
    //   const data = this.tabs[this.tabIndex].data
    //   if (!key && !data.length) {
    //     this.getBcRoselist()
    //   } else if (key && !data.length) {
    //     this.getFollowConfig()
    //   }
    // },
    getBcRoselist() {
      // 获取常态分成配置
      const data = {
        bcid: bcid
      }
      this.$axios.getBcRoselist(data).then((res) => {
        this.list = res.data.data
        console.log('list', this.list)
        this.loading = false
      })
    },
    setRate(arr, type) {
      arr.forEach((e) => {
        if (e.type === 1 || type === 'second') {
          e.rate = NP.times(e.rate, 100)
        }
        if (e.second) {
          this.setRate(e.second, 'second')
        }
      })
    },
    getFollowConfig() {
      // 获取随动分成比例（市场费）
      const data = {
        bcid: bcid,
        configType: this.tabIndex
      }
      this.$axios.getFollowConfig(data).then((res) => {
        const list = res.data.data
        if (this.tabIndex < 2) {
          this.setRate(list)
        }
        this.tabs[this.tabIndex].data = list
        this.loading = false
      })
    },
    openStarConfig(e) {
      // 查看星级阶梯式配置
      const hide = this.$message.loading('请稍后')
      starId = e.id
      this.modalStarTitle = e.name
      const data = {
        bcid: bcid,
        rose_id: starId
      }
      if (this.tabIndex) {
        this.$axios.getFollowStarConfig(data).then((res) => {
          hide()
          const stars = res.data.data
          stars.forEach((e) => {
            e.rate = NP.times(e.rate, 100)
          })
          this.stars = stars
          this.modalStarVisible = true
        })
      } else {
        this.$axios.getNormalStarConfig(data).then((res) => {
          hide()
          const stars = res.data.data
          stars.forEach((e) => {
            e.rate = NP.times(e.rate, 100)
          })
          this.stars = stars
          this.modalStarVisible = true
        })
      }
    },
    handleModalStarOk() {
      // 确定星级保存
      const data = {
        role: this.stars.map((e) => {
          return {
            id: e.id,
            rate: NP.divide(e.rate, 100)
          }
        })
      }
      if (this.tabIndex) {
        this.$axios.editFollowStarConfig(data).then(() => {
          this.$message.success('保存成功')
          this.handleModalStarCancel()
        })
      } else {
        this.$axios.editNormalStarConfig(data).then(() => {
          this.$message.success('保存成功')
          this.handleModalStarCancel()
        })
      }
    },
    handleModalStarCancel() {
      // 取消星级编辑
      this.modalStarVisible = false
      this.modalStarTitle = ''
      this.confirmModalStarLoading = false
      this.starEditable = false
      this.stars = []
    },
    edit(i) {
      // 编辑
      const target = this.list[i]
      this.editingKey = target.id
      this.newRate =  parseFloat(target.rate*100).toFixed(0)
      console.log("newRate",this.newRate)
      target.editable = true
      this.list.splice(i, 1, target)
    },
    save(i) {
      // 保存
      const target = this.list[i]
      if (this.newRate >= 30) {
        this.$message.error('可分配比例不能超出30%')
        return
      }
       if (!/(^[1-9]\d*$)/.test(this.newRate)) {
        this.$message.error('请输入正整数0也不能输入')
        return false
      }
     

      target.rate = this.newRate
      delete target.editable
      this.list.splice(i, 1, target)
      this.editingKey = ''
      this.saveData(target.rose_id)
    },
    saveData(id) {
      // 保存修改
      const data = {
        bcid: bcid,
        rose_id: id,
        rate: this.newRate/100
      }
      this.saveNormalConfig(data)
    },
    saveNormalConfig(data) {
      this.$axios.getEditrole(data).then(() => {
        this.$message.success('保存成功')
        this.getBcRoselist()
      })
    },
    cancel(i) {
      // 取消
      const target = this.list[i]
      delete target.editable
      this.list.splice(i, 1, target)
      this.editingKey = ''
      this.newRate = ''
    },
    handleModalOpen() {
      // 打开新建角色
      this.modalVisible = true
      if (!this.roleData.length) {
        this.$axios.getRoseRate().then((res) => {
          this.roleData = res.data.data
        })
      }
    },
    handleModalCancel() {
      // 取消新建角色
      this.$refs.ruleModalForm.resetFields()
      this.modalVisible = false
      this.confirmModalLoading = false
      this.performanceType = 1
      this.stars = []
    },
    onRoseChange(value, selectedOptions) {
      // 监听角色选择

      const data = selectedOptions[selectedOptions.length - 1]
      //    console.log("value, selectedOptions",value,data)
      this.rose_idConfig = data.rose_id
      //   if (data.type === 2) {
      //     this.getStarConfig(data.userType)
      //   } else {
      //     this.stars = []
      //     this.performanceType = 1
      //   }
      //   this.roleType = data.type
    },
    getStarConfig(e) {
      // 获取对应角色的星级配置
      const data = {
        userType: e
      }
      this.$axios.getStarConfig(data).then((res) => {
        const lists = res.data.data
        this.stars = lists.map((e) => {
          return {
            id: e.id,
            level: e.level,
            name: e.name,
            rate: ''
          }
        })
      })
    },
    handleModalOk() {
      // 确定新建角色
      this.$refs.ruleModalForm.validate((valid) => {
        if (valid) {
          const data = {
            bcid: bcid,
            rose_id: this.rose_idConfig
          }
          if (!/(^[1-9]\d*$)/.test(this.modalForm.rate)) {
            this.$message.error('请输入正整数0也不能输入')
            return false
          }
          if (this.modalForm.rate < 30) {
            data.rate = this.modalForm.rate / 100
          } else {
            this.$message.error('比例不能超出30')
            return
          }

          this.getAddrole(data)
        }
      })
    },
    addNormalConfig(data) {
      // 新增常态比例
      this.$axios.addNormalConfig(data).then(() => {
        // this.confirmModalLoading = false
        this.$message.success('操作成功')
        this.handleModalCancel()
        this.getBcRoselist()
        this.rose_idConfig = ''
        // this.getNormalConfig()
      })
    },
    getAddrole(data) {
      // 新增随动比例
      //   data.role.forEach((e) => {
      //     e.configType = this.tabIndex
      //   })
      this.$axios.getAddrole(data).then(() => {
        this.confirmModalLoading = false
        this.$message.success('操作成功')
        this.handleModalCancel()
        this.getBcRoselist()
      })
    },
    handleModalSubOpen(id) {
      // 打开新建二级角色
      parentId = id
      this.modalSubVisible = true
      if (!this.roleData.length) {
        this.$axios.getRoleData().then((res) => {
          this.roleData = res.data.data
        })
      }
    },
    handleModalSubCancel() {
      // 取消新建二级角色
      this.$refs.ruleModalSubForm.resetFields()
      this.modalSubVisible = false
      this.confirmModalSubLoading = false
      parentId = ''
    },
    handleModalSubOk() {
      // 确定新建二级角色
      this.$refs.ruleModalSubForm.validate((valid) => {
        if (valid) {
          this.confirmModalSubLoading = true
          const data = {
            bcid: bcid,
            rose_id:
              this.modalSubForm.rose_id[this.modalSubForm.rose_id.length - 1],
            rate: NP.divide(this.modalSubForm.rate, 100),
            parentId: parentId,
            baseint: 2
          }
          if (this.tabIndex) {
            this.addFollowSubConfig(data)
          } else {
            this.addNormalSubConfig(data)
          }
        } else {
          return false
        }
      })
    },
    addNormalSubConfig(data) {
      // 新增常态分成二级比例配置
      this.$axios.addNormalSubConfig(data).then(() => {
        this.confirmModalSubLoading = false
        this.$message.success('操作成功')
        this.handleModalSubCancel()
        this.getNormalConfig()
      })
    },
    addFollowSubConfig(data) {
      // 新增随动分成二级比例配置
      data.configType = this.tabIndex
      this.$axios.addFollowSubConfig(data).then(() => {
        this.confirmModalSubLoading = false
        this.$message.success('操作成功')
        this.handleModalSubCancel()
        this.getFollowConfig()
      })
    },
    editSub(i, subIndex) {
      // 编辑
      const item = this.tabs[this.tabIndex].data[i]
      const target = item.second[subIndex]
      this.editingKey = target.id
      this.newRate = target.rate
      target.editable = true
      item.second.splice(subIndex, 1, target)
    },
    saveSub(i, subIndex) {
      // 保存
      const item = this.tabs[this.tabIndex].data[i]
      const target = item.second[subIndex]
      if (this.newRate > 100) {
        this.$message.error('可分配比例不能超出100%')
        return
      }

      target.rate = this.newRate
      delete target.editable
      this.editingKey = ''
      item.second.splice(subIndex, 1, target)
      this.saveDataSub(target.id)
    },
    saveDataSub(id) {
      // 保存修改
      const data = {
        role: [
          {
            bcid: bcid,
            id: id,
            rate: NP.divide(this.newRate, 100)
          }
        ]
      }
      if (this.tabIndex) {
        // 动态
        this.saveFollowConfig(data)
      } else {
        // 常态
        this.saveNormalConfig(data)
      }
    },
    saveFollowConfig(data) {
      // 保存动态比例修改
      data.configType = this.tabIndex
      this.$axios.saveFollowConfig(data).then(() => {
        this.$message.success('保存成功')
      })
    },
    cancelSub(i, subIndex) {
      // 取消
      const item = this.tabs[this.tabIndex].data[i]
      const target = item.second[subIndex]
      delete target.editable
      this.editingKey = ''
      this.newRate = ''
      item.second.splice(subIndex, 1, target)
    },
    onDel(e) {
      this.$confirm({
        title: '确定删除吗?',
        onOk: () => {
          const data = {
            bcid: bcid,
            id: e.id
          }
          this.$axios.getDelBcRole(data).then(() => {
            this.$message.success('操作成功')
            this.getBcRoselist()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.table-wrap .ant-input {
  width: 200px;
}

.popover-title {
  justify-content: space-between;
  line-height: 32px;
}

.popover-btn {
  padding: 0;
}

.star {
  border: 1px solid #ebebeb;
}

.popover-title,
.star .title,
.star .body .item {
  display: flex;
}

.star .title {
  font-weight: bold;
  background-color: #fafafa;
}

.star .item {
  width: 100%;
  height: 52px;
  line-height: 52px;
  text-align: center;
}

.star .title,
.star .body .item:not(:last-child) {
  border-bottom: 1px solid #ebebeb;
}

.star .left {
  width: 66%;
}

.star .left .label {
  margin-left: 22px;
}

.star .right {
  width: 34%;
  border-left: 1px solid #ebebeb;
}

.star .body .right {
  padding: 0 16px;
}
</style>
